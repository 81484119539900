<template>
  <div>

    <ca-popup
        :header="'Delete Coupon'"
        :toggle="openDeletePopup"
        @open="confirmDelete"
        @close="cancelDelete"
        v-show="currentCouponData !== null"
      >
        <template v-slot:content>
          Are you sure you want to delete the coupon?
        </template>
        <template v-slot:actions>
          <ca-button @click.native="deleteCoupon">Yes</ca-button>
        </template>
    </ca-popup>

    <ca-popup
      header="Edit Coupon"
      :use-close-action="false"
      :toggle="openPopup"
      @open="openActiveCouponPopup"
      @close="closeActiveCouponPopup"
      v-show="currentCouponData !== null"
    >
      <template v-slot:content>
        <br />
        <br />
        <form class="form">
          <div class="form__group">
            <label for="couponName" class="form__label">Coupon Name</label>
            <input
              type="text"
              class="form__input"
              placeholder="e.g Uptimize Tech"
              id="couponName"
              v-model="currentCouponData.id"
            />
          </div>

          <div class="form__group">
            <label for="discountPrice" class="form__label"
              >Discount Price</label
            >
            <input
              type="number"
              class="form__input"
              placeholder="e.g 40"
              id="discountPrice"
              v-model="currentCouponData.percent_off"
            />
          </div>

             <div class="form__group">
        <label for="duration" class="form__label">Duration</label>
        <select
          name="duration"
          id="duration"
          class="form__input"
          v-model="currentCouponData.duration"
        >
          <option  value="once" selected>Once</option>
          <option  value="repeating">Repeating</option>
          <option  value="forever">Forever</option>
        </select>
      </div>
        </form>
      </template>

      <template v-slot:actions>
        <ca-button :disable="loading" @click.native="editCoupon">Edit Coupon <span v-if="loading"><i class="fa fa-spinner fa-spin"></i></span>  </ca-button></template
      >
    </ca-popup>
    <grid-table
      :column-defs="columnDefs"
      :row-data="rowData"
      v-if="rowData.length > 0"
    >
      <template v-slot:cell-renderer="slotProps">
        <div>
          <!-- <span
            class="icon-button mr-1"
            @click="openActiveCouponPopup(slotProps.currentRowData)"
            ><img
              :src="require('@/assets/images/icons/edit.svg')"
              alt="edit icon"
          /></span> -->
          <span class="icon-button"
            @click="confirmDelete(slotProps.currentRowData)"
            ><img
              :src="require('@/assets/images/icons/delete.svg')"
              alt="delete icon"
          /></span>
        </div>
      </template>
    </grid-table>
  </div>
</template>

<script>
// import active_coupons from "../db/active_coupons";
import GridTable from "../../../components/tables/GridTable";
import gridTableParentMixin from "../../../../mixins/gridTableParentMixin";
import axios from "../../../../axios";
import CaPopup from "../../../components/popup/CaPopup";
import CaButton from "../../../components/buttons/CaButton";
export default {
  mixins: [gridTableParentMixin],
  name: "ActiveCoupons",
  components: { CaButton, CaPopup, GridTable },
  data() {
    return {
      columnDefs: [
        {
          headerName: "ID",
          field: "id",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Code",
          field: "nmae",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Discount Price (%)",
          field: "percent_off",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Date Created",
          field: "created",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Expiring Date",
          field: "redeem_by",
          filter: true,
          sortable: true,
        },
        {
          headerName: "Use Count",
          field: "times_redeemed",
          filter: true,
          sortable: true,
        },

        {
          headerName: "Actions",
          field: "actions",
          hasCellRendererFramework: true,
        },
      ],
      loading: false,
      rowData: [],
      dataResourceUrl: `${this.$store.state.server.requestUri}/admin/coupons`,
      currentCouponData: {},
      openPopup: false,
      openDeletePopup: false,
    };
  },
  methods: {
    getTableData(resourceUrl) {
      const loading = this.$vs.loading();
      axios
        .get(`${resourceUrl}`)
        .then((res) => {
          this.rowData = res.data.data.data.filter((coupon) => {
            return coupon.valid === true;
          });
          console.log(res.data.data.data);
          loading.close();

          // this.closePageLoader();
        })
        .catch((err) => {
          loading.close();
          this.$toast(err.response.data.message, {
            type: "error",
            position: "top-center",
          });
          console.log(err.response);
        });
    },
    openActiveCouponPopup(popUpData = null) {
      if (popUpData !== null) {
        this.currentCouponData = popUpData;
        console.log(this.currentCouponData);
      }
      this.openPopup = true;
    },

    closeActiveCouponPopup() {
      this.openPopup = false;
    },

    editCoupon(){
      const loading = this.$vs.loading();
      this.loading = true;
      axios
        .patch(
          `${this.$store.state.server.requestUri}/admin/coupons/${this.$route.params.couponId}`,
          this.currentCouponData
        )
        .then((res) => {
          loading.close();
          this.loading = false;
          console.log(res);
          this.$toast("Coupon edited successfully", {
            type: "success",
            position: "top-center",
          });
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.loading = false;
          console.log(err.response);
        });
    },

    cancelDelete() {
      this.openDeletePopup = false;
    },
    confirmDelete(popUpData = null) {
      if (popUpData !== null) {
        this.currentCouponData = popUpData;
      }
      this.openDeletePopup = true;
    },
     deleteCoupon() {
      console.log(this.currentUser);
      // this.cancelDelete();
      const loading = this.$vs.loading();
      axios
        .delete(
          `${this.$store.state.server.requestUri}/admin/coupons/${this.currentCouponData.id}`
        )
        .then((res) => {
          loading.close();
          this.$toast(
            `User with id ${this.currentCouponData.id} deleted successfully`,
            {
              position: "top-center",
              type: "success",
            }
          );
          console.log(res);
        })
        .catch((err) => {
          loading.close();
          this.$toast(`An error occurred while deleting user`, {
            position: "top-center",
            type: "error",
          });
          console.log(err.response);
        });
    },
  },

  created() {
    this.getTableData(`${this.dataResourceUrl}?${this.defaultQueryString}`);
    // this.rowData = active_coupons;
  },
};
</script>

<style scoped></style>
